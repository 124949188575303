@use '../../styles/variables' as *;

.header-button {
  align-items: center;
  background-color: transparent;
  border-radius: 0.5rem;
  box-shadow: $transparentBoxShadowPressed;
  color: $headerColor;
  display: flex;
  font-family: $fontFamily;
  font-size: $fontsizeHeaderButton;
  font-weight: bold;
  justify-content: center;
  padding: 8px;
  text-align: center;
  transition:
    background-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &__active {
    color: $headerRedColor;
    text-decoration: underline;
  }

  &:hover:not(&:active) {
    background-color: $blackBgHover;
  }

  &:active {
    box-shadow: $blackBoxShadowPressed;
  }

  &:focus-visible {
    outline: $orangeOutline;
  }
}
