@use '../../styles/variables' as *;

.header-dropdown-button {
  font-family: $fontFamily;
  position: relative;

  &__button {
    align-items: center;
    border-radius: 0.5rem;
    box-shadow: $transparentBoxShadowPressed;
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    font-weight: bold;
    gap: 0.5rem;
    justify-content: center;
    padding: 0.5rem;
    transition:
      background-color 0.2s ease-in-out,
      box-shadow 0.2s ease-in-out,
      color 0.2s ease-in-out;

    &__label {
      align-items: center;
      color: $headerColor;
      display: flex;
      font-size: $fontsizeHeaderButton;
      font-weight: $fontWeightHeaderButton;
      justify-content: center;
    }

    &__icon {
      color: $headerColor;
      font-size: $fontsizeHeaderButton;
      transition: transform 0.3s ease-in-out;
    }

    &:focus-visible {
      outline: $orangeOutline;
    }

    &:hover:not(&:active) {
      background-color: $blackBgHover;
    }

    &:active {
      box-shadow: $blackBoxShadowPressed;
    }
  }

  &__options {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: $boxShadowHeader;
    display: flex;
    flex-direction: column;
    left: 50%;
    max-height: 0rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: calc(100% + 0.25rem);
    transform: translateX(-50%);
    transition:
      max-height 0.5s ease-in-out,
      padding 0.5s ease-in-out;

    &__option {
      border-radius: 0.5rem;
      color: $headerColor;
      font-size: $fontsizeHeaderButtonOption;
      font-weight: $fontWeightHeaderButtonOption;
      min-width: max-content;
      padding: 0.25rem;
      text-align: left;
      transition:
        background-color 0.2s ease-in-out,
        box-shadow 0.2s ease-in-out,
        color 0.2s ease-in-out;
      width: 100%;

      &:hover:not(&:active) {
        background-color: $blackBgHover;
      }

      &:active {
        box-shadow: $blackBoxShadowPressed;
      }

      &:focus-visible {
        outline: $orangeOutline;
      }
    }
  }

  &__expanded {
    .header-dropdown-button {
      &__button__icon {
        transform: rotate(180deg);
      }

      &__options {
        max-height: 200px;
        padding: 0.5rem;
      }
    }
  }

  &__active {
    .header-dropdown-button__button {
      &__label {
        color: $headerRedColor;
        text-decoration: underline;
      }

      &__icon {
        color: $headerRedColor;
      }
    }
  }
}
