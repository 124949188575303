@use '../../styles/variables' as *;

.values-tabs {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: all 0.4s ease;
  &__options {
    display: flex;

    gap: 1rem;
    margin-bottom: 2rem;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__option {
    font-weight: bold;
    text-align: start;
    color: #adadad;
    cursor: pointer;
    width: 20%;
    font-size: 1rem;
    transition:
      color 0.3s,
      background-color 0.3s;
    border-radius: 5px;

    @media (max-width: 768px) {
      width: 40%;
    }

    &:hover {
      color: $redBg;
    }

    &.active {
      color: $redBg;
    }
  }

  &__content {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    p {
      font-size: 1.2rem;
      width: 100%; /* Make it fit the screen */
      text-align: center;
      @media (max-width: 768px) {
        padding: 0 8%;
      }
    }
  }

  .values-tabs__icon {
    height: 15rem;
    object-fit: contain;
    margin-bottom: 1rem;
    transition: all 0.3s ease;
  }

  .values-tabs__title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .values-tabs__description {
    max-width: 600px;
    font-size: 1rem;
    font-weight: 300;
    line-height: 2;
    text-align: start;
  }
}
