@use '../../styles/variables' as *;

.header-mobile {
  font-family: $fontFamily;
  position: relative;
  width: 100vw;

  &__content {
    align-items: center;
    background-color: $whiteBg;
    box-shadow: $boxShadowHeader;
    display: flex;
    height: 4.5rem;
    justify-content: space-between;
    padding: 0.5rem;
    position: relative;
    width: 100%;
    z-index: 1;

    &__button {
      align-items: center;
      border-radius: 0.25rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      justify-content: center;
      height: 3.5rem;
      width: 3.5rem;

      &__icon {
        color: $headerRedColor;
        font-size: 1.3125rem;
        height: 1.3333333333em;
        width: 1.3333333333em;
      }

      &__label {
        font-size: 0.75rem;
        color: $headerRedColor;
        font-weight: $fontWeightHeaderButton;
      }

      &:hover:not(&:active) {
        background-color: $blackBgHover;
      }

      &:active {
        box-shadow: $blackBoxShadowPressed;
      }

      &:focus-visible {
        outline: $orangeOutline;
      }
    }

    &__logo {
      align-items: center;
      border-radius: 0.5rem;
      display: flex;
      justify-content: center;
      width: 50%;

      &__image {
        padding: 0.5rem;
        width: 100%;
      }

      &:focus-visible {
        outline: $orangeOutline;
      }
    }

    &__nothing {
      height: 3.5rem;
      width: 3.5rem;
    }
  }

  &__options {
    align-items: center;
    background-color: $whiteBg;
    box-shadow: $boxShadowMenuMobile;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 4.5rem);
    max-height: 0;
    overflow: hidden;
    padding: 0 1rem;
    position: absolute;
    top: 100%;
    transition:
      box-shadow 0.5s ease-in-out,
      max-height 0.5s ease-in-out,
      padding 0.5s ease-in-out;
    width: 100vw;
    z-index: 0;

    &__divider {
      background-color: $menuMobileSeparator;
      height: 0.0625rem;
      width: 90%;
    }
  }

  &__expanded {
    .header-mobile__options {
      max-height: calc(100vh - 4.5rem);
      padding: 1rem;
    }
  }
}
