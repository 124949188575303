@use '../../styles/variables' as *;

.home {
  background-color: $homeBg;
  display: flex;
  flex-direction: row;
  font-family: $fontFamily;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  &__content {
    --home-font-size-description: #{$fontSizeHomeDescription};
    --home-font-size-title: #{$fontSizeHomeTitle};
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-left: 4rem;
    width: 45%;
    z-index: 1;

    @media (max-width: 1100px) {
      --home-font-size-description: 0.75rem;
      --home-font-size-title: 3.75rem;
    }

    &__title {
      color: $homeColor;
      font-size: var(--home-font-size-title);
      font-weight: 800;
      line-height: 100%;
      @media (max-width: 376px) {
        font-size: 2.3rem;
      }

      &__tagged {
        background-color: $homeTaggedColor;
        border-radius: 17px;
        color: #ffffff;
        padding-bottom: 8px;
        padding: 0px 8px 8px 8px;
        position: relative;
      }
    }

    &__description {
      color: $homeColor;
      font-size: var(--home-font-size-description);
      line-height: 120%;
    }

    &__buttons {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 1rem 2rem;
      justify-content: flex-start;
      padding: 2rem 0 0 1rem;
      width: 100%;
      flex-wrap: wrap;
    }
  }

  &__image {
    align-items: flex-end;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    z-index: 0;

    &__img {
      height: 100%;
      margin-right: -3.2vw;
    }
  }
}

@media (max-width: 768px) {
  .home {
    &__content{
      padding: 0 2rem;
      justify-content: flex-start;
      padding-top: 2rem;
      width: 100%;
      &__description{
        padding-top: 2rem;
        font-size: 1rem;
      }
      &__buttons{
        gap: 1rem;
        padding: 0;
        padding-top:  2rem;
      }
    }

    &__image {
      &__img {
        height: auto;
        bottom: 0;
        position: absolute;
        transform: translate(-15%, 0) scale(1.2);
      }
    }
  }
}

.stroke{
  position: absolute;
  z-index: 0;
  right: 10%;
  height: 90%;
  @media (max-width: 768px) {
    display: none;
  }
}