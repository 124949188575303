@use '../../styles/variables' as *;

.home-button {
  border-radius: 0.5rem;
  min-width: 10.75rem;
  padding: 1rem;
  transition:
    background-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out,
    outline 0.2s ease-in-out;

  @media (max-width: 1100px) {
    padding: 0.5rem;
  }
  @media (max-width: 768px) {
    min-width: 6.75rem;
    padding: 0.5rem;
  }

  &__red {
    --home-button-bg: #{$buttonRedBg};
    --home-button-color: #{$buttonRedColor};

    &.home-button {
      &:hover:not(:active) {
        --home-button-bg: #{$buttonRedBgHover};
      }
    }
  }

  &__white {
    --home-button-bg: #{$buttonWhiteBg};
    --home-button-color: #{$buttonWhiteColor};

    &.home-button {
      &:hover:not(:active) {
        --home-button-bg: #{$buttonWhiteBgHover};
      }
    }
  }

  background-color: var(--home-button-bg);
  color: var(--home-button-color);

  &:active {
    box-shadow: $blackBoxShadowPressed;
  }

  &:focus-visible:not(:active) {
    outline: $blackOutline;
  }

  &__label {
    font-family: $fontFamily;
    font-size: $fontSizeHomeButton;
    font-weight: 700;
  }
}
