@use '../../styles/variables' as *;

.contact-modal {
  &__overlay {
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1441;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &.show {
      opacity: 1;
    }
  }

  &__content {
    background: white;
    border-radius: 1rem;
    max-height: 90%;
    max-width: 31.25rem;
    overflow: hidden;
    position: relative;
    width: 90%;

    animation: slideUp 0.3s ease-out;

    &.enter {
      transform: translateY(0);
      opacity: 1;
      animation: slideUp 0.3s ease-out;
    }

    &.exit {
      transform-origin: top;
      transform: translateY(-100%);
      opacity: 0;
      animation: slideDown 0.3s ease-in;
    }
  }

  &__close {
    align-items: center;
    background-color: #f5a754;
    border-radius: 100%;
    border: none;
    box-shadow: $boxShadowCard;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    height: 40px;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translate(-50%, calc(-100% - 1rem));
    transition:
      box-shadow 0.2s ease-in-out,
      background-color 0.2s ease-in-out;
    width: 40px;

    &:hover:not(&:active) {
      background-color: #d9944a;
    }

    &:active {
      box-shadow: $blackBoxShadowPressed;
    }

    > * {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// Definición de la animación de entrada
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
