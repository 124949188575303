@use 'global.css';
@use 'variables' as *;
@use 'mixins' as *;

* {
  font-family:$fontFamily;
}
// @media (max-width: 639px) {
// }

// @media only screen and (min-width: 640px) and (max-width: 1439px) {
// }

// @media only screen and (min-width: 1440px) {
// }

.app {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
