.btnHome {
  transition: all 0.3s ease-in-out;
  font-family: 'Dosis', sans-serif;
}

.btnHome {
  background-image: linear-gradient(135deg, #ff7678 0%, #ff7678 100%);
  border-radius: 50px;
  border: none;
  box-shadow: 0 20px 30px -6px rgba(238, 103, 97, 0.5);
  color: white;
  cursor: pointer;
  font-family: 'Inter';
  font-size: 1rem;
  height: 60px;
  outline: none;
  padding: 0 20px;
  transition: all 0.3s ease-in-out;
  width: auto;
}

.btnHome:hover {
  transform: translateY(3px);
  box-shadow: none;
}

.btnHome:active {
  opacity: 0.5;
}


.home-button__red{
  @extend .btnHome;
  background-image: linear-gradient(135deg, #ffcc96 0%, #fea94f 100%);
}

@media (max-width: 480px) { 
  .btnHome {
    font-size: 0.8rem;
    height: 50px;
  }
}