@use '../../styles/variables' as *;

.title-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: $fontFamily;
  gap: 1rem;
  justify-content: center;
  max-width: 75%;

  @media (max-width: 600px) {
    max-width: 95%;
    margin:0 auto;
  }

  &__title {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    justify-content: center;
    width: fit-content;

    &__text {
      color: #474747;
      font-size: 2.5rem;
      line-height: 1;
      padding-bottom: 1rem;
      font-weight: 700;
      text-align: center;
      @media (max-width: 600px) {
        font-size: 3.5rem;
      }
    }

    &__line {
      height: 0.25rem;
      width: 80%;

      &__color {
        background-color: #be3850;
        border-radius: 0.5rem;
        height: 100%;
        width: 100%;
      }
    }
  }

  &__description {
    color: #606060;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    white-space: pre-line;
  }

  &__left {
    align-items: flex-start;

    .title-section {
      &__title {
        align-items: flex-start;
        @media (max-width: 600px) {
          margin:0 auto;
        }
        

        &__text {
          text-align: left;
          font-size: 4.5rem;
          @media (max-width: 600px) {
            text-align: center;
            font-size: 3.5rem;
          }
        }

        &__line {
          width: 50%;
        }
      }

      &__description {
        text-align: left;
      }
    }
  }

  &__right {
    align-items: flex-end;

    .title-section {
      &__title {
        align-items: flex-end;

        &__text {
          text-align: right;
        }

        &__line {
          width: 50%;
        }
      }

      &__description {
        text-align: right;
      }
    }
  }
}

// @media (max-width: 50rem) {
// }
