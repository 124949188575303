.carousel {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 90rem;
  overflow: hidden;
  padding: 2rem;
  position: relative;
  width: 100%;

  --carousel-min-width: 25%;

  @media (max-width: 1200px) {
    --carousel-min-width: 33%;
  }

  @media (max-width: 900px) {
    --carousel-min-width: 50%;
  }

  @media (max-width: 600px) {
    --carousel-min-width: 100%;
  }

  .carousel__inner {
    display: flex;
    transform: translateX(
      calc(-1 * var(--current-index, 0) * var(--carousel-min-width))
    );
  }

  .carousel__item {
    box-sizing: border-box;
    min-width: var(--carousel-min-width);
    padding: 10px;

    img {
      height: 7.5rem;
      object-fit: contain;
      transition: transform 0.5s ease-in-out;
      width: 100%;
    }
  }

  .carousel__container {
    overflow: hidden;
  }
}
