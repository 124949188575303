@use '../../styles/variables' as *;

.proveedores-sst {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__content {
    display: flex;
    font-family: $fontFamily;
    justify-content: center;
    max-width: 90rem;
    width: 100%;

    .our-products {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding: 2rem;
      width: 100%;

      &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        flex-direction: row;

        @media (max-width: 900px) {
          flex-direction: column;
          gap: 2rem;
        }

        &__products {
          align-content: center;
          display: grid;
          gap: 2rem 0.5rem;
          grid-template-columns: repeat(4, 1fr);
          justify-items: center;
          min-width: 60%;

          @media (max-width: 1250px) {
            grid-template-columns: repeat(3, 1fr);
          }

          @media (max-width: 900px) {
            gap: 1rem;
            grid-template-columns: repeat(2, 1fr);
          }

          @media (max-width: 600px) {
            grid-template-columns: repeat(2, 1fr);
          }

          &__product {
            align-items: center;
            border-radius: 1rem;
            box-shadow: $boxShadowCard;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            height: 9.375rem;
            justify-content: space-between;
            transition:
              transform 0.2s,
              background-color 0.2s ease-in-out;
            width: 9.375rem;

            &__icon {
              font-size: 60px;
              padding-top: 1rem;
            }

            &__label {
              color: gray;
              font-size: 0.7rem;
              font-weight: 700;
              padding-left: 1rem;
              padding-right: 1rem;
              text-align: center;
              user-select: none;
            }

            &__know-more {
              align-items: center;
              background-color: #ee7777;
              border-bottom-left-radius: 1rem;
              border-bottom-right-radius: 1rem;
              display: flex;
              flex-direction: row;
              font-size: 12px;
              gap: 0.25rem;
              height: 1rem;
              justify-content: center;
              transition: filter 0.2s ease-in-out;
              width: 100%;

              svg {
                color: white;
              }

              &__label {
                color: white;
              }
            }

            &:hover {
              transform: scale(1.1);
              background-color: whitesmoke;

              .our-products__content__products__product__know-more {
                filter: brightness(90%);
              }
            }
          }
        }
      }

      &__download {
        align-items: center;
        background-color: #f59454;
        border-radius: 1rem;
        box-shadow: $boxShadowCard;
        color: white;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        justify-content: center;
        margin-top: 2rem;
        min-width: 60%;
        padding: 1rem 2rem;

        &__label {
          font-size: 24px;
          text-align: center;
        }

        &__button {
          align-items: center;
          background-color: white;
          border-radius: 0.5rem;
          border: none;
          color: #1b1b1b;
          cursor: pointer;
          display: flex;
          font-size: 1.125rem;
          gap: 0.5rem;
          height: 40px;
          justify-content: center;
          padding: 0 1rem;
          transition:
            box-shadow 0.2s ease-in-out,
            background-color 0.2s ease-in-out;

          &__label {
            font-size: 1rem;
            font-weight: 700;
          }

          &:hover:not(&:active) {
            background-color: whitesmoke;
          }

          &:active {
            box-shadow: $blackBoxShadowPressed;
          }

          > * {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  @media screen and (orientation: landscape) {
    .banner__logo__img {
      margin-right: -15%;
      max-width: 65%;
    }
  }
}
