@use '../../styles/variables' as *;

.header-mobile-dropdown-button {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: $fontFamily;
  justify-content: center;
  width: 100%;

  &__button {
    align-items: center;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: space-between;
    padding: 0.5rem;
    width: 100%;

    &__title {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      justify-content: center;

      &__icon {
        color: $menuMobileColor;
        font-size: $fontsizeMenuMobile;
        height: calc(4em / 3em);
        width: calc(4em / 3em);
      }

      &__label {
        color: $menuMobileColor;
        font-size: $fontsizeMenuMobile;
        font-weight: 700;
      }
    }

    &__trigger {
      color: $menuMobileColor;
      font-size: $fontsizeMenuMobile;
      height: calc(4em / 3em);
      transition: transform 0.3s ease-in-out;
      width: calc(4em / 3em);
    }

    &:focus {
      z-index: 1;
    }

    &:active {
      box-shadow: $blackBoxShadowPressed;
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 0;
    overflow: hidden;
    padding: 0;
    transition:
      max-height 0.3s ease-in-out,
      padding 0.3s ease-in-out;
    width: 100%;

    &__option {
      border-radius: 0.25rem;
      color: $menuMobileColor;
      font-size: $fontsizeMenuMobileOption;
      font-weight: 400;
      padding: 0.5rem;
      width: 100%;

      &:active {
        box-shadow: $blackBoxShadowPressed;
      }
    }
  }

  &__expanded {
    .header-mobile-dropdown-button {
      &__button {
        &__trigger {
          transform: rotate(90deg);
        }
      }

      &__options {
        max-height: 200px;
        padding: 0.125rem 1rem 0.5rem 1rem;
      }
    }
  }

  &:focus-visible {
    outline: $orangeOutline;
  }
}
