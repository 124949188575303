@use '../../styles/variables' as *;

.card{
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover{
        border: 2px solid $redBg;
        transform: scale(1.05);
    }
}
.circle-icon {
    background-color:$redBg ;
    border-radius: 3rem;
    padding: 10px;
}

.cards-container{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 20rem));
    gap: 2rem;
    justify-content: center;
    @media (max-width: 1024px) {
        grid-template-columns: repeat(2, minmax(0, 1fr)); // 2 columnas en tabletas
      }
    
      @media (max-width: 768px) {
        grid-template-columns: 1fr; // 1 columna en dispositivos móviles
      }
}

.divider{
    border-bottom: 3px solid $redBg;
    width: 70%;
}