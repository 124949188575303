$fontFamily: 'Inter', 'Roboto', sans-serif;

$bannerDescriptionColor: #ffffff !default;
$bannerTitleColor: #ffab46 !default;
$headerColor: #1b1b1b !default;
$headerRedColor: #c9465c !default;
$homeColor: #2c2c2c !default;
$homeTaggedColor: #ffab46 !default;
$menuMobileColor: #606060 !default;

$menuMobileSeparator: #b3b3b3 !default;

$homeBg: #e7e7e7 !default;
$orangeBg: #f5a754 !default;
$orangeLightBg: #f9c68b !default;
$redBg: #be3850 !default;
$redLightBg: #e1687c !default;
$whiteBg: #ffffff !default;

$transparentBoxShadowPressed: inset 0 0 0 0 rgba(#ffffff, 0) !default;
$blackBoxShadowPressed: inset 0 0.125rem 0.25rem 0.125rem rgba(#212121, 0.16);

$blackBgHover: rgba(#212121, 0.08) !default;

$orangeOutline: 2px solid #f5a754 !default;
$blackOutline: 2px solid black !default;

$boxShadowHeader:
  1px 4px 4px rgba(100, 100, 100, 0.08),
  2px 2px 4px rgba(100, 100, 100, 0.24) !default;
$boxShadowMenuMobile:
  1px 4px 4px rgba(100, 100, 100, 0.2),
  2px 2px 4px rgba(100, 100, 100, 0.4) !default;
$boxShadowCard:
  0px -2px 4px rgba(0, 0, 0, 0.03),
  0px 2px 6px rgba(0, 0, 0, 0.05),
  0px 4px 10px rgba(0, 0, 0, 0.12) !default;

$fontSizeBannerDescription: 1rem;
$fontSizeBannerTitle: 5rem;
$fontsizeHeaderButton: 1.125rem;
$fontsizeHeaderButtonOption: 1rem;
$fontsizeHeaderContact: 1rem;
$fontSizeHomeButton: 1rem;
$fontSizeHomeDescription: 1rem;
$fontSizeHomeTitle: 5rem;
$fontsizeMenuMobile: 1.25rem;
$fontsizeMenuMobileOption: 1.125rem;

$fontWeightHeaderContact: 400;
$fontWeightHeaderButton: 700;
$fontWeightHeaderButtonOption: 400;

$buttonRedBg: #c61422;
$buttonRedBgHover: #a0101b;
$buttonRedColor: #ffffff;

$buttonWhiteBg: #ffffff;
$buttonWhiteBgHover: #f0f0f0;
$buttonWhiteColor: #484848;
