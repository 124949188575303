@use '../../styles/variables' as *;

.appWeb {
  align-items: center;
  background-color: #c9465c;
  color: white;
  display: flex;
  gap: 3rem;
  justify-content: center;
  width: 100vw;
  h1 {
    font-size: 2rem;
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    width: 100%;

    img{
      width: 60%;
    }

    div{
      gap: 1rem;
      width: 100%;
    }
  }
}

.ser-pyme-sst {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: $fontFamily;
    justify-content: center;
    max-width: 90rem;
    width: 100%;

    .starter-pack {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 100%;
      justify-content: center;
      padding: 2rem;
      width: 100%;

      &__items {
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(4, 1fr);
        justify-content: center;
        padding: 1rem;

        &__item {
          --starter-pack-item-font-size-description: 0.875rem;
          --starter-pack-item-font-size-icon: 1.5rem;
          --starter-pack-item-font-size-title: 1.125rem;
          align-items: center;
          background-color: white;
          border-radius: 1rem;
          box-shadow: $boxShadowCard;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          justify-content: center;
          overflow: hidden;
          padding: 1rem 2rem;

          @media (max-width: 540px) {
            --starter-pack-item-font-size-description: 12;
            --starter-pack-item-font-size-icon: 1.25rem;
            --starter-pack-item-font-size-title: 1rem;
          }

          &__icon {
            align-items: center;
            background-color: $redLightBg;
            border-radius: 100%;
            color: $whiteBg;
            display: flex;
            font-size: var(--starter-pack-item-font-size-icon);
            justify-content: center;
            min-height: 2em;
            min-width: 2em;
          }

          &__content {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            height: 100%;
            width: 100%;

            &__title {
              color: $homeColor;
              font-size: var(--starter-pack-item-font-size-title);
              font-weight: 700;
              text-align: center;
            }

            &__description {
              color: $homeColor;
              font-size: var(--starter-pack-item-font-size-description);
              font-weight: 400;
              text-align: center;
            }
          }
        }

        @media (max-width: 1250px) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 900px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 600px) {
          grid-template-columns: 1fr;
        }
      }
    }

    .subscription {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      justify-content: center;
      padding: 2rem;
      width: 100%;

      &__tabs {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;

        &__label {
          color: #1b1b1b;
          font-size: 20px;
          text-align: center;
          font-weight: 700;
        }

        &__container {
          align-items: center;
          display: flex;
          flex-direction: row;
          gap: 1rem;
          justify-content: center;

          @media (max-width: 400px) {
            flex-direction: column;
          }

          &__tab {
            align-items: center;
            background-color: white;
            border-radius: 1rem;
            border: 0.125rem solid #be3850;
            cursor: pointer;
            display: flex;
            justify-content: center;
            min-width: 128px;
            padding: 0.5rem 1rem;
            transition:
              background-color 0.2s ease-in-out,
              color 0.2s ease-in-out,
              outline 0.2s ease-in-out;

            &__label {
              color: #be3850;
              font-size: 18px;
              font-weight: 700;
              user-select: none;
            }

            &:hover:not(&:active):not(&__active) {
              background-color: rgb(240, 240, 240);
            }

            &:focus-visible {
              outline: $orangeOutline;
            }

            &:active {
              box-shadow: $blackBoxShadowPressed;
            }

            &__active {
              background-color: #be3850;

              .subscription__tabs__container__tab__label {
                color: white;
              }

              &:hover {
                background-color: #c14d63;
              }
            }
          }
        }
      }

      &__container {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        width: 100%;

        &__label {
          font-size: 20px;
          font-weight: 700;
        }

        &__plans {
          align-items: center;
          display: grid;
          gap: 1rem;
          grid-template-columns: repeat(3, 1fr);
          justify-items: center;
          margin-top: 1rem;
          width: 100%;

          @media (max-width: 950px) {
            grid-template-columns: 1fr;
          }

          &__plan {
            border-radius: 1rem;
            border: 2px solid #c61422;
            box-shadow: $boxShadowCard;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            height: 100%;
            max-width: 21.875rem;
            padding: 2rem;

            &__basic {
              --plan-icon-bg: linear-gradient(135deg, #cd7f32, #8c7853);
              --plan-icon-color: #ffffff;
              --plan-price-color: #8b4513;
            }

            &__plus {
              --plan-icon-bg: linear-gradient(135deg, #c0c0c0, #d3d3d3);
              --plan-icon-color: #000000;
              --plan-price-color: #808080;
            }

            &__premium {
              --plan-icon-bg: linear-gradient(135deg, #ffd700, #ffc107);
              --plan-icon-color: #2c2c2c;
              --plan-price-color: #b8860b;
            }

            &__title {
              align-items: center;
              display: flex;
              flex-direction: row;
              gap: 0.5rem;
              justify-content: flex-start;
              width: 100%;

              &__icon {
                align-items: center;
                background: var(--plan-icon-bg);
                border-radius: 0.5rem;
                color: var(--plan-icon-color);
                display: flex;
                font-size: 0.75rem;
                height: 1.5rem;
                justify-content: center;
                width: 1.5rem;
              }

              &__label {
                color: #1b1b1b;
                font-size: 1rem;
              }
            }

            &__content {
              align-items: center;
              display: flex;
              flex-direction: column;
              gap: 0;
              justify-content: center;

              &__price {
                align-items: center;
                color: var(--plan-price-color);
                display: flex;
                font-size: 1.5rem;
                font-weight: 700;
                justify-content: center;

                @media (max-width: 1050px) {
                  font-size: 1.25rem;
                }

                &__iva {
                  font-size: 1rem;
                  font-weight: 400;
                  white-space: break-spaces;

                  @media (max-width: 1050px) {
                    font-size: 0.75rem;
                  }
                }
              }

              &__aditional {
                color: #484848;
                font-size: 0.75rem;
              }

              &__order,
              &__more-information {
                align-items: center;
                background-color: var(--btn-background-color);
                border-radius: 1rem;
                color: var(--btn-color);
                cursor: pointer;
                display: flex;
                font-size: 1rem;
                font-weight: 700;
                justify-content: center;
                margin-top: 1rem;
                min-width: 6.25rem;
                padding: 0.5rem 1rem;
                transition:
                  background-color 0.2s ease-in-out,
                  color 0.2s ease-in-out,
                  outline 0.2s ease-in-out;
                user-select: none;
                width: 100%;

                &:hover:not(&:active):not(&__active) {
                  background-color: var(--btn-background-color-hover);
                }

                &:focus-visible {
                  outline: $orangeOutline;
                }

                &:active {
                  box-shadow: $blackBoxShadowPressed;
                }
              }

              &__order {
                --btn-background-color-hover: #a0101b;
                --btn-background-color: #c61422;
                --btn-color: #ffffff;
              }

              &__more-information {
                --btn-background-color-hover: #f5b672;
                --btn-background-color: #f5a754;
                --btn-color: #484848;
              }

              &__compliance {
                color: #1b1b1b;
                font-size: 1rem;
                margin-top: 1rem;
                text-align: center;
              }
            }
          }
        }
      }
    }

    .support {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      justify-content: center;
      padding: 2rem;
      width: 100%;

      &__label {
        color: #484848;
        font-size: 1rem;
        line-height: 2rem;
        max-width: 75%;
      }

      &__protections {
        align-items: center;
        display: grid;
        gap: 1.5rem;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;

        @media (max-width: 950px) {
          grid-template-columns: 1fr;

          .support__protections__protection {
            min-width: 15rem;
            width: 50%;
          }
        }

        &__protection {
          --protection-padding: 1rem;
          align-items: center;
          border-radius: 1rem;
          box-shadow: $boxShadowCard;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          overflow: hidden;
          max-width: 75%;

          &__title {
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-content: center;
            padding: var(--protection-padding);
            text-align: center;

            &__icon {
              color: #f59454;
              font-size: 40px;
            }

            &__text {
              color: #1b1b1b;
              font-size: 1rem;
              font-weight: 700;
            }
          }

          &__wave {
            height: 2rem;
            position: relative;
            width: 100%;

            &__gray,
            &__orange {
              height: 110%;
              left: 0;
              position: absolute;
              top: 0;
              width: 100%;
            }
          }

          &__description {
            background-color: #f59454;
            color: white;
            font-size: 0.875rem;
            font-weight: 700;
            height: 100%;
            padding: var(--protection-padding);
            text-align: center;
            width: 100%;
          }
        }
      }
    }
  }

  .main {
    width: 100%;
  }

  @media screen and (orientation: landscape) {
    .banner__logo__img {
      margin-right: -10%;
      max-width: 60%;
    }
  }
}
