.main {
  height: calc(100vh - 5rem);
  max-height: 670px;
  width: 100%;
  overflow: hidden;

  @media (max-width: 50rem) {
    max-height: 100%;
    height: calc(100vh - 4.5rem);
  }

  // height: calc(100vh - 7rem);
  position: relative;

  // @media (max-width: 768px) {
  //   min-height: calc(100vh - 7rem);
  // }
}
