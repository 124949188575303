@use '../../styles/variables' as *;

.banner {
  background-color: $homeBg;
  display: flex;
  flex-direction: row;
  font-family: $fontFamily;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  &__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 2.2222222222vw;
    height: 100%;
    justify-content: center;
    padding-left: 4.444444444vw;
    width: 50%;
    z-index: 1;

    &__title {
      color: $bannerTitleColor;
      font-size: 7vw;
      font-weight: 700;
      line-height: 100%;
    }

    &__description {
      color: $bannerDescriptionColor;
      font-size: 1.7vw;
      line-height: 120%;
      white-space: break-spaces;
    }
  }

  &__image {
    align-items: flex-end;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    z-index: 0;

    &__img {
      filter: blur(0.3125rem);
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &::after {
      background-color: black;
      content: '';
      height: 100%;
      opacity: 0.8;
      position: absolute;
      width: 100%;
    }
  }

  &__logo {
    align-items: flex-end;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    z-index: 0;

    &__img {
      max-height: 100%;
      max-width: 50%;
      object-fit: contain;
      width: 100%;
    }
  }
}

@media screen and (orientation: portrait) {
  .banner {
    &__content {
      gap: 4vh;
      justify-content: flex-start;
      padding: 4vh;
      width: 100%;

      &__title {
        font-size: 6vh;
      }

      &__description {
        font-size: 2.2vh;
        white-space: break-spaces;
        line-height: 160%;
      }
    }

    &__image {
      align-items: flex-end;
      display: flex;
      height: 100%;
      justify-content: flex-end;
      position: absolute;
      width: 100%;
      z-index: 0;

      &__img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }

      &::after {
        background-color: black;
        content: '';
        height: 100%;
        opacity: 0.8;
        position: absolute;
        width: 100%;
      }
    }

    &__logo {
      &__img {
        width: 80%;
        max-width: 100%;
      }
    }
  }
}
