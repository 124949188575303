@use '../../styles/variables' as *;

.header {
  box-shadow: $boxShadowHeader;
  position: fixed;
  z-index: 1001;

  &__contact-us {
    align-items: center;
    background-color: $orangeBg;
    display: flex;
    height: 2rem;
    justify-content: center;
    width: 100vw;

    &__container{
      align-items: center;
      background-color: #ffab46;
      border-radius: 28px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 10rem;
      margin-top: 4%;
      padding: 11px;

      h1{
        font-size: 1.5rem;
        font-weight: 800;
        text-shadow: 2px 2px 2px #9e5e11;
        color: white;
      }
    }

    &__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 1440px;
      padding: 0 3rem;
      width: 100%;

      &__number {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        justify-content: center;

        &__icon {
          color: white;
          font-family: $fontFamily;
          font-size: $fontsizeHeaderContact;
        }

        &__text {
          color: white;
          font-family: $fontFamily;
          font-size: 14px;
          font-weight: $fontWeightHeaderContact;
        }
      }

      &__social {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 0.25rem;
        justify-content: center;

        &__icon {
          align-items: center;
          border-radius: 0.25rem;
          color: white;
          cursor: pointer;
          display: flex;
          font-family: $fontFamily;
          font-size: $fontsizeHeaderContact;
          height: 1.5rem;
          justify-content: center;
          width: 1.5rem;
          background-color: transparent;
          transition: background-color 200ms ease-in-out;

          &__facebook {
            &:hover,
            &:focus-visible {
              background-color: #3b5998;
            }
          }

          &__linkedin {
            &:hover,
            &:focus-visible {
              background-color: #0077b5;
            }
          }

          &__whatsapp {
            &:hover,
            &:focus-visible {
              background-color: #1ee663;
            }
          }

          &__instagram {
            &:hover,
            &:focus-visible {
              background-color: #cd486b;
            }
          }

          &__twitter {
            &:hover,
            &:focus-visible {
              background-color: #000;
            }
          }

          &:focus-visible {
            outline: none;
          }
        }
      }
    }
  }

  &__nav-bar {
    align-items: center;
    background-color: $whiteBg;
    display: flex;
    height: 5rem;
    justify-content: center;
    width: 100vw;

    &__content {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: space-between;
      max-width: 1440px;
      padding: 0 1rem;
      width: 100%;

      &__logo {
        align-items: center;
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;

        &__image {
          width: 8rem;
          padding: 0.5rem;
        }

        &:focus-visible {
          outline: $orangeOutline;
        }
      }

      &__buttons {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        justify-content: center;
        padding-right: 2rem;
      }
    }
  }

  &__mobile {
    display: none;
  }

  &-support {
    height: 5rem;
  }

  &-container {
    width: 100%;
  }
}

@media (max-width: 50rem) {
  .header {
    box-shadow: none;

    &__contact-us,
    &__nav-bar {
      display: none;
    }

    &__mobile {
      display: flex;
    }

    &-support {
      height: 4.5rem;
    }
  }
}
