@use '../../styles/variables' as *;

.background {
  background-color: #f59454;
  width: 100vw;
}

.admins-card {
  background-color: #ebebeb;
}

b {
  display: inline-block;
  color: white;
  font-weight: 700;
  background-color: $orangeBg;
  border-radius: 1rem;
  padding: 0 0.625rem;
}

.people {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 70%;
  img {
    @media (max-width: 768px) {
      height: 28rem;
      max-width: none;
      transform: translateX(-45%);
    }
    @media (max-width: 376px) {
      height: 17rem;
      max-width: none;
      transform: translateX(-45%);
    }
  }
  @media (min-width: 1440px) and (max-width: 1920px) {
    width: 60%;
  }
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem; // Espaciado entre tarjetas
  margin: 2rem 0;

  .single-card {
    background-color: white;
    border-radius: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-around;
    padding: 1rem 0.5rem;
    text-align: center;
    transition:
      transform 0.3s ease,
      box-shadow 0.3s ease;
    width: 300px;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    img {
      max-width: 6.25rem; // Tamaño de icono
      margin: 0 auto; // Centrado y separación inferior
    }

    &__title {
      font-size: 1.25rem;
      font-weight: 700;
    }

    &__description {
      font-size: 0.7rem;
      color: #666;
    }
  }
}

.admins-card {
  border-radius: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem;
  overflow: hidden;
  text-align: center;
  width: 500px;

  &__container {
    align-items: center;
    display: flex;
    gap: 2rem;
    padding: 0 1rem;
    max-height: 14rem;
  }

  img {
    background-color: rgb(199, 199, 199);
    border-radius: 100%;
    max-width: 60%;
    height: 60%;
  }

  .admin-name {
    text-align: start;
    span {
      display: block;
      font-size: 1rem;
    }
  }
}

.adkar {
  max-width: 70%;
  margin: 0 auto;
}

.our-values {
  background-color: #f0f2f5;
  display: flex;
  height: 80vh;
  justify-content: center;
  margin: auto 0;
  width: 100%;

  h1 {
    font-size: 2.5rem;
    font-weight: 800;
    color: #474747;
    text-align: center;
  }
}

.bienestar-program {
  @extend .our-values;
  background-color: white;
  height: auto;

  @media (max-width: 768px) {
    img{
      max-width: 100%;
    }
  }
}

.react-player {
  margin: 0 auto;
  width: 80%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .admins-card__container {
    gap: 1rem;
  }

  .admins-card {
    width: auto;
    .admin-name {
      span {
        font-size: 0.7rem;
      }
    }
  }

  .background {
    height: auto;
    p {
      width: 100%;
      font-size: 0.9rem;
    }
  }

  .about-us h1 {
    font-size: 2rem; /* Reduce font size for smaller screens */
    line-height: 1.2;
    width: 100%; /* Make it fit the screen */
    text-align: center; /* Center align for better appearance */
    padding: 0 1rem;
  }

  p {
    font-size: 1.2rem;
    width: 100%; /* Make it fit the screen */
    text-align: center;
    padding: 0 1rem;
  }
}
