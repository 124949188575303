@use '../../styles/variables' as *;

.outsourcing-sst {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    font-family: $fontFamily;
    justify-content: center;
    max-width: 90rem;
    width: 100%;

    .request {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      justify-content: center;
      padding: 2rem;
      width: 100%;

      &__logo {
        height: 5.4375rem;
        object-fit: contain;
      }

      &__life-cycle {
        object-fit: contain;
      }

      &__info-list {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        width: 100%;

        &__item {
          align-items: center;
          background: #f9f9f9;
          border-radius: 8px;
          box-shadow: $boxShadowCard;
          display: flex;
          flex-direction: row;
          transition: transform 0.3s ease;

          &__number {
            align-items: center;
            border-radius: 0.5rem;
            color: white;
            display: flex;
            font-size: 3rem;
            font-weight: bold;
            height: 100%;
            justify-content: center;
            min-height: 1.5em;
            min-width: 1.5em;
            text-shadow: 0.3125rem 0.3125rem 0.3125rem rgba(0, 0, 0, 0.5);
          }

          &__texts {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            justify-content: center;
            padding: 1rem 1.5rem;
            width: 100%;

            &__text {
              align-items: center;
              display: flex;
              flex-direction: row;
              justify-content: center;
              gap: 0.5rem;

              svg {
                font-size: 1rem;
                min-height: 1em;
                min-width: 1em;
              }

              &__label {
                color: #333;
                font-size: 1.125rem;
                line-height: 1.5;
              }
            }
          }
        }
      }
    }

    .subscription {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      justify-content: center;
      padding: 2rem;
      width: 100%;

      .plan-table {
        --table-bg-color-even: #f1f1f1;
        --table-bg-color-odd: transparent;
        --table-border-color: #a2a2a2;
        --table-border-radius: 1rem;
        --table-border-width: 0.0625rem;
        --table-tagged-bg-color: #be3850;
        --table-tagged-bg-color-light: #c64c62;
        --table-tagged-border: 2px solid black;
        border-collapse: separate;
        border-spacing: 0;
        font-family: $fontFamily;
        width: 100%;

        th,
        td {
          padding: 8px;
          text-align: center;
        }

        th:first-child,
        td:first-child {
          width: 40%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        /* Border line */
        th,
        tr:not(:last-child) td {
          border-bottom: var(--table-border-width) solid
            var(--table-border-color);
        }

        th:first-child {
          border-left: var(--table-border-width) solid transparent;
          border-top: var(--table-border-width) solid transparent;
        }

        /* Background color */
        thead tr th:not(:first-child) {
          background-color: var(--table-bg-color-odd);
        }

        tbody tr {
          &:nth-child(odd) {
            background-color: var(--table-bg-color-even);
          }

          &:nth-child(even) {
            background-color: var(--table-bg-color-odd);
          }
        }

        /* Border radius */
        thead tr th {
          &:nth-child(2) {
            border-top-left-radius: var(--table-border-radius);
          }

          &:last-child {
            border-top-right-radius: var(--table-border-radius);
          }
        }

        tbody tr {
          &:last-child td {
            &:first-child {
              border-bottom-left-radius: var(--table-border-radius);
            }

            &:last-child {
              border-bottom-right-radius: var(--table-border-radius);
            }
          }
        }

        /* Tagged */
        thead tr th:nth-child(2) {
          border-top-left-radius: var(--table-border-radius);
          border-top-right-radius: var(--table-border-radius);
        }

        tbody tr:last-child td:nth-child(2) {
          border-bottom-left-radius: var(--table-border-radius);
          border-bottom-right-radius: var(--table-border-radius);
        }

        th:nth-child(2),
        td:nth-child(2) {
          background-color: var(--table-tagged-bg-color) !important;
          border-bottom: var(--table-border-width) solid
            var(--table-tagged-bg-color) !important;
          border-left: var(--table-tagged-border);
          border-right: var(--table-tagged-border);
          color: #fff;
          font-weight: bold;
        }

        tbody tr:nth-child(odd) td:nth-child(2) {
          background-color: var(--table-tagged-bg-color-light) !important;
        }

        tbody tr:nth-child(even) td:nth-child(2) {
          background-color: var(--table-tagged-bg-color) !important;
        }

        thead th:nth-child(2) {
          border-top: var(--table-tagged-border);
        }

        tbody tr:last-child td:nth-child(2) {
          border-bottom: var(--table-tagged-border) !important;
        }
      }

      &__order {
        align-items: center;
        background-color: var(--btn-background-color);
        border-radius: 1rem;
        color: var(--btn-color);
        cursor: pointer;
        display: flex;
        font-size: 1rem;
        font-weight: 700;
        justify-content: center;
        min-width: 6.25rem;
        padding: 0.5rem 1rem;
        transition:
          background-color 0.2s ease-in-out,
          color 0.2s ease-in-out,
          outline 0.2s ease-in-out;
        user-select: none;
        width: 100%;

        &:hover:not(&:active):not(&__active) {
          background-color: var(--btn-background-color-hover);
        }

        &:focus-visible {
          outline: $orangeOutline;
        }

        &:active {
          box-shadow: $blackBoxShadowPressed;
        }
      }

      &__order {
        --btn-background-color-hover: #f5b672;
        --btn-background-color: #f5a754;
        --btn-color: #484848;
      }
    }
  }
  @media screen and (orientation: landscape) {
    .banner__logo__img {
      margin-right: -10%;
      max-height: 110%;
      max-width: 60%;
    }
  }
}

@media screen and (orientation: portrait) {
  .outsourcing-sst__content {
    .request__info-list__item {
      flex-direction: column;

      &__number {
        width: 100%;
        font-size: 30px;
      }

      &__texts {
        gap: 0.25rem;

        &__text {
          svg {
            font-size: 0.75rem;
          }

          &__label {
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}
