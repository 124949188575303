@use '../../styles/variables' as *;

.footer {
  align-items: center;
  background-color: #4f4f4f;
  display: flex;
  flex-direction: column;
  font-family: $fontFamily;
  gap: 2rem;
  justify-content: center;
  padding: 2rem;
  width: 100%;

  &__sections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    max-width: 1440px;
    width: 100%;

    @media (max-width: 37.5rem) {
      flex-direction: column;

      .footer__sections__section {
        width: 100%;
      }
    }

    &__section {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: calc(25% - 1.5rem);

      &__title {
        align-items: flex-start;
        color: white;
        font-size: 1rem;
        font-weight: 700;
        justify-content: center;
      }

      &__items {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        gap: 0.5rem;

        &__item {
          align-items: center;
          display: flex;
          flex-direction: row;
          gap: 0.25rem;
          justify-content: flex-start;
          width: 100%;

          &:hover {
            .footer__sections__section__items__item {
              &__icon,
              &__label {
                color: rgb(255, 255, 255);
              }
            }
          }

          &__icon {
            align-items: center;
            color: rgb(199, 199, 199);
            display: flex;
            height: 1.5rem;
            justify-content: center;
            transition: color 0.3s ease-in-out;
            width: 1.5rem;
          }

          &__label {
            color: rgb(199, 199, 199);
            transition: color 0.3s ease-in-out;
          }
        }

        &__social {
          flex-direction: row;
          flex-wrap: wrap;

          .footer__sections__section__items {
            &__item-social {
              align-items: center;
              background-color: transparent;
              border-radius: 0.25rem;
              color: white;
              cursor: pointer;
              display: flex;
              font-family: $fontFamily;
              font-size: 1.5rem;
              min-height: 2.5rem;
              justify-content: center;
              transition: background-color 200ms ease-in-out;
              min-width: 2.5rem;

              &__facebook {
                &:hover,
                &:focus-visible {
                  background-color: #3b5998;
                }
              }

              &__linkedin {
                &:hover,
                &:focus-visible {
                  background-color: #0077b5;
                }
              }

              &__whatsapp {
                &:hover,
                &:focus-visible {
                  background-color: #1ee663;
                }
              }

              &__instagram {
                &:hover,
                &:focus-visible {
                  background-color: #cd486b;
                }
              }

              &__twitter {
                &:hover,
                &:focus-visible {
                  background-color: #000;
                }
              }

              &:focus-visible {
                outline: none;
              }
            }
          }
        }
      }
    }
  }

  &__separator {
    height: 1px;
    max-width: 1440px;
    width: 100%;

    &__line {
      background-color: rgba(255, 255, 255, 0.4);
      height: 100%;
      width: 100%;
    }
  }

  &__bottom {
    display: grid;
    flex-direction: row;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    max-width: 1440px;
    width: 100%;

    @media (max-width: 37.5rem) {
      grid-template-columns: repeat(1, 1fr);
    }

    &__left {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;

      &__logo {
        height: 25px;
      }

      &__label {
        color: rgb(199, 199, 199);
      }
    }

    &__right {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      justify-content: flex-end;
      min-width: 100%;
      width: min-content;

      &__link {
        color: rgb(199, 199, 199);
        transition: color 0.3s ease-in-out;

        &:hover {
          color: white;
        }
      }

      &__separator {
        height: 100%;
        width: 0.0625rem;

        &__line {
          background-color: rgba(255, 255, 255, 0.4);
          height: 100%;
          width: 100%;
        }
      }

      &__logo {
        height: 1.25rem;
      }
    }
  }
}
